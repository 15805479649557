import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { convertListToMap, utilNewExpirationDate } from '../../global/utils/storeUtils';

import apiUtils from '../../global/utils/api';

import {
  handleFetchListPending
  , handleFetchListRejected
  , shouldFetch
  , INITIAL_STATE
  , handleInvalidateQuery
  , handleInvalidateQueries
} from '../../global/utils/storeUtils';

// Define all custom/external API endpoints

// NOTE: must define the full api route here, including the /api/${resourceName}
export const trainingSitesByGroup = groupId => `redstone/api/sites/by-_group/${groupId}`
// TODO: change to "training", define the set of these globally somewhere as we add more (library, etc)

/**
 * The functions below, called thunks, allow us to perform async logic. They
 * can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
 * will call the thunk with the `dispatch` function as the first argument. Async
 * code can then be executed and other actions can be dispatched. Thunks are
 * typically used to make async requests.
 * 
 * In practice we won't dispatch these directly, they will be dispatched by externalService which has a nicer api built on hooks.
 */


export const fetchExternalListAtEndpoint = createAsyncThunk(
  'external/fetchList' // this is the action name that will show up in the console logger.
  , async (query) => {
    const callAPI = query.includes('/redstone') ? apiUtils.callRedstoneAPI : apiUtils.callAPI;
    // NOTE: the full api route is defined here, not just the part following the resourceName
    const endpoint = `${query}`; // example: `/api/groups/logged-in?${queryString}`
    const response = await callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);



// next define the store's initial state, all of our store utils rely on a specific state shape, so use the constant
// add a byType map to store all resources by type since we have no idea what we're getting back from the external server(s)
const initialState = { ...INITIAL_STATE, byType: {} };

// define the externalSlice. This is a combination of actions and reducers. More info: https://redux-toolkit.js.org/api/createSlice
export const externalSlice = createSlice({
  name: 'external'
  , initialState
  /**
   * The `reducers` field lets us define reducers and generate associated actions.
   * Unlike the selectors defined at the bottom of this file, reducers only have access
   * to this specific reducer and not the entire store.
   * 
   * Again, we will not dispatch these directly, they will be dispatched by externalService.
   */
  , reducers: {
    invalidateQuery: handleInvalidateQuery
    , invalidateQueries: handleInvalidateQueries
  }

  /**
   * The `extraReducers` field lets the slice handle actions defined elsewhere,
   * including actions generated by createAsyncThunk or in other slices.
   * We'll use them to track our server request status.
   * 
   * We'll add a case for each API call defined at the top of the file to dictate
   * what happens during each API call lifecycle.
   */
  , extraReducers: (builder) => {
    builder

      // permission protected list fetches
      .addCase(fetchExternalListAtEndpoint.pending, handleFetchListPending)

      // requires special handling to add the list(s) to the byType map
      .addCase(fetchExternalListAtEndpoint.fulfilled, (state, action) => {
        const { totalPages, totalCount, ...otherData } = action.payload;
        // add all resource lists in otherData to the byType map
        Object.keys(otherData).forEach(key => {
          const resourceList = otherData[key];
          // convert the array of objects to a map
          const resourceMap = convertListToMap(resourceList, '_id');
          // add the resource map to the byType map
          state.byType[key] = { ...state.byType[key], ...resourceMap };
        });
        // find the query object for this fetch in the listQueries map and update query info
        const listQuery = state.listQueries[action.meta.arg];
        // set the rest of the query info
        listQuery.totalPages = totalPages;
        listQuery.totalCount = totalCount;
        listQuery.status = 'fulfilled';
        listQuery.receivedAt = Date.now();
        listQuery.expirationDate = utilNewExpirationDate();
        listQuery.otherData = otherData || {};
      })
      .addCase(fetchExternalListAtEndpoint.rejected, handleFetchListRejected)
  }
});

// export the actions for the reducers defined above
export const { invalidateQuery, invalidateQueries } = externalSlice.actions;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export const fetchListIfNeeded = (queryKey, listFetch = fetchExternalListAtEndpoint) => (dispatch, getState) => {
  const externalQuery = getState().external.listQueries[queryKey];
  if(shouldFetch(externalQuery)) {
    // console.log('Fetching externalQuery list', queryKey);
    dispatch(listFetch(queryKey));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
};

export default externalSlice.reducer;