import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiUtils from '../../global/utils/api';

import {
  handleCreateFulfilled
  , handleFetchSinglePending
  , handleFetchSingleFulfilled
  , handleFetchSingleRejected
  , handleFetchListPending
  , handleFetchListFulfilled
  , handleFetchListRejected
  , handleMutationPending
  , handleMutationFulfilled
  , handleMutationRejected
  , handleDeletePending
  , handleDeleteFulfilled
  , handleDeleteRejected
  , shouldFetch
  , INITIAL_STATE
  , handleInvalidateQuery
  , handleInvalidateQueries
  , handleAddSingleToList
} from '../../global/utils/storeUtils';


// First define all API calls for userProfile
/**
 * The functions below, called thunks, allow us to perform async logic. They
 * can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
 * will call the thunk with the `dispatch` function as the first argument. Async
 * code can then be executed and other actions can be dispatched. Thunks are
 * typically used to make async requests.
 * 
 * In practice we won't dispatch these directly, they will be dispatched by userProfileService which has a nicer api built on hooks.
 */

// CREATE
export const sendCreateUserProfile = createAsyncThunk(
  'userProfile/sendCreate'
  , async (newUserProfile) => {
    const endpoint = `/api/user-profiles`;
    const response = await apiUtils.callAPI(endpoint, 'POST', newUserProfile);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// READ
export const fetchSingleUserProfile = createAsyncThunk(
  'userProfile/fetchSingle'
  , async (id) => {
    const endpoint = `/api/user-profiles/${id}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchUserProfileList = createAsyncThunk(
  'userProfile/fetchList' // this is the action name that will show up in the console logger.
  , async (listArgs) => {
    const endpoint = `/api/user-profiles${listArgs}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// UPDATE
export const sendUpdateUserProfile = createAsyncThunk(
  'userProfile/sendUpdate'
  , async ({ _id, ...updates }) => {
    const endpoint = `/api/user-profiles/${_id}`;
    const response = await apiUtils.callAPI(endpoint, 'PUT', updates);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// DELETE
export const sendDeleteUserProfile = createAsyncThunk(
  'userProfile/sendDelete'
  , async (id) => {
    const endpoint = `/api/user-profiles/${id}`;
    const response = await apiUtils.callAPI(endpoint, 'DELETE');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// next define the store's initial state, all of our store utils rely on a specific state shape, so use the constant
const initialState = { ...INITIAL_STATE };

// define the userProfileSlice. This is a combination of actions and reducers. More info: https://redux-toolkit.js.org/api/createSlice
export const userProfileSlice = createSlice({
  name: 'userProfile'
  , initialState
  /**
   * The `reducers` field lets us define reducers and generate associated actions.
   * Unlike the selectors defined at the bottom of this file, reducers only have access
   * to this specific reducer and not the entire store.
   * 
   * Again, we will not dispatch these directly, they will be dispatched by userProfileService.
   */
  , reducers: {
    invalidateQuery: handleInvalidateQuery
    , invalidateQueries: handleInvalidateQueries
    , addUserProfileToList: handleAddSingleToList
  }

  /**
   * The `extraReducers` field lets the slice handle actions defined elsewhere,
   * including actions generated by createAsyncThunk or in other slices.
   * We'll use them to track our server request status.
   * 
   * We'll add a case for each API call defined at the top of the file to dictate
   * what happens during each API call lifecycle.
   */
  , extraReducers: (builder) => {
    builder
      // CREATE
      .addCase(sendCreateUserProfile.fulfilled, handleCreateFulfilled)

      // READ
      .addCase(fetchSingleUserProfile.pending, handleFetchSinglePending)
      .addCase(fetchSingleUserProfile.fulfilled, handleFetchSingleFulfilled)
      .addCase(fetchSingleUserProfile.rejected, handleFetchSingleRejected)
      .addCase(fetchUserProfileList.pending, handleFetchListPending)
      // because lists are returned from the server named for their resource, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchUserProfileList.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'userProfiles'))
      .addCase(fetchUserProfileList.rejected, handleFetchListRejected)

      // UPDATE
      .addCase(sendUpdateUserProfile.pending, handleMutationPending)
      .addCase(sendUpdateUserProfile.fulfilled, handleMutationFulfilled)
      .addCase(sendUpdateUserProfile.rejected, handleMutationRejected)
      // .addCase(sendUpdateUserProfile.fulfilled, (state, action) => handleMutationFulfilled(state, action, (newState, action) => {
      //   // by passing this optional callback we now have access to the new state if we want to do something else with it, this works for all reducer handlers
      // }))

      // DELETE
      .addCase(sendDeleteUserProfile.pending, handleDeletePending)
      .addCase(sendDeleteUserProfile.fulfilled, handleDeleteFulfilled)
      .addCase(sendDeleteUserProfile.rejected, handleDeleteRejected)
  }
});

// export the actions for the reducers defined above
export const { invalidateQuery, invalidateQueries, addUserProfileToList } = userProfileSlice.actions;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export const fetchListIfNeeded = (queryKey) => (dispatch, getState) => {
  const userProfileQuery = getState().userProfile.listQueries[queryKey];
  if(shouldFetch(userProfileQuery)) {
    // console.log('Fetching userProfile list', queryKey);
    dispatch(fetchUserProfileList(queryKey));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
};

export const fetchSingleIfNeeded = (id) => (dispatch, getState) => {
  const userProfileQuery = getState().userProfile.singleQueries[id];
  if(shouldFetch(userProfileQuery)) {
    dispatch(fetchSingleUserProfile(id));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
}

export default userProfileSlice.reducer;
