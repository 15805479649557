import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiUtils from '../../global/utils/api';

import {
  handleCreateFulfilled
  , handleFetchSinglePending
  , handleFetchSingleFulfilled
  , handleFetchSingleFromListFulfilled
  , handleFetchSingleRejected
  , handleFetchListPending
  , handleFetchListFulfilled
  , handleFetchListRejected
  , handleMutationPending
  , handleMutationFulfilled
  , handleMutationRejected
  , handleDeletePending
  , handleDeleteFulfilled
  , handleDeleteRejected
  , shouldFetch
  , INITIAL_STATE
  , handleInvalidateQuery
  , handleInvalidateQueries
  , handleAddSingleToList
} from '../../global/utils/storeUtils';


// First define all API calls for group

// // define and export the strings for the different specific group endpoints once here because the idea of using strings in the component gives me hives.
// // we'll catch for these strings on the server side and apply the correct permissions to the query.
// // these are passed in to the groupService hooks at the component level as the endpoint argument.
export const myGroupsEndpoint = 'logged-in';
// this `redstone` prefix means the endpoint is on the redstone server, not rome.
// redstone only allows a user to fetch their own admin groups, but userId is checked on the server to make sure it matches the logged in user. It's kind of redundant but required for now.
export const adminGroupsByUserEndpoint = (userId) => {
  if(!userId) return null;
  return `redstone/by-admin/${userId}`
};
export const joinableGroupsEndpoint = 'public-joinable';
export const publicGroupsEndpoint = 'public-landing-page';

export const adminGroupApiAccessEndpoint = (groupId) => {
  if(!groupId) return null; 
  return `api-access/${groupId}`
}
/**
 * The functions below, called thunks, allow us to perform async logic. They
 * can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
 * will call the thunk with the `dispatch` function as the first argument. Async
 * code can then be executed and other actions can be dispatched. Thunks are
 * typically used to make async requests.
 * 
 * In practice we won't dispatch these directly, they will be dispatched by groupService which has a nicer api built on hooks.
 */

// CREATE
export const sendCreateGroup = createAsyncThunk(
  'group/sendCreate'
  , async (newGroup) => {
    const endpoint = `/api/groups`;
    const response = await apiUtils.callAPI(endpoint, 'POST', newGroup);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// READ
export const fetchSingleGroup = createAsyncThunk(
  'group/fetchSingle'
  , async (id) => {
    const endpoint = `/api/groups/${id}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchGroupList = createAsyncThunk(
  'group/fetchList' // this is the action name that will show up in the console logger.
  , async (listArgs) => {
    const endpoint = `/api/groups${listArgs}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// for each resource we can add as many endpoints as we want in this format and we only need two actions to handle them.
export const fetchSingleGroupAtEndpoint = createAsyncThunk(
  'group/fetchSingleWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const endpoint = `/api/groups${query}` // example: `/api/groups/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchGroupListAtEndpoint = createAsyncThunk(
  'group/fetchListWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    // catch for the redstone prefix and call the correct api function
    const callAPI = query.includes('/redstone') ? apiUtils.callRedstoneAPI : apiUtils.callAPI;
    const endpoint = `/api/groups${query}`; // example: `/api/groups/logged-in?${queryString}`
    const response = await callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// UPDATE
export const sendUpdateGroup = createAsyncThunk(
  'group/sendUpdate'
  , async ({ _id, ...updates }) => {
    const endpoint = `/api/groups/${_id}`;
    const response = await apiUtils.callAPI(endpoint, 'PUT', updates);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// DELETE
export const sendDeleteGroup = createAsyncThunk(
  'group/sendDelete'
  , async (id) => {
    const endpoint = `/api/groups/${id}`;
    const response = await apiUtils.callAPI(endpoint, 'DELETE');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// JOIN GROUP
export const sendJoinPublicGroup = createAsyncThunk(
  'group/sendJoinPublic'
  , async (id) => {
    const endpoint = `/api/groups/${id}/join-public`;
    const response = await apiUtils.callAPI(endpoint, 'POST');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const sendCheckSecureGroupLanding = createAsyncThunk(
  'group/sendCheckSecureGroupLanding'
  , async (query) => {
    const endpoint = `/api/groups/secure-landing${query}`;
    const response = await apiUtils.callAPI(endpoint, 'POST');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


// next define the store's initial state, all of our store utils rely on a specific state shape, so use the constant
const initialState = { ...INITIAL_STATE };

// define the groupSlice. This is a combination of actions and reducers. More info: https://redux-toolkit.js.org/api/createSlice
export const groupSlice = createSlice({
  name: 'group'
  , initialState
  /**
   * The `reducers` field lets us define reducers and generate associated actions.
   * Unlike the selectors defined at the bottom of this file, reducers only have access
   * to this specific reducer and not the entire store.
   * 
   * Again, we will not dispatch these directly, they will be dispatched by groupService.
   */
  , reducers: {
    invalidateQuery: handleInvalidateQuery
    , invalidateQueries: handleInvalidateQueries
    , addGroupToList: handleAddSingleToList
  }

  /**
   * The `extraReducers` field lets the slice handle actions defined elsewhere,
   * including actions generated by createAsyncThunk or in other slices.
   * We'll use them to track our server request status.
   * 
   * We'll add a case for each API call defined at the top of the file to dictate
   * what happens during each API call lifecycle.
   */
  , extraReducers: (builder) => {
    builder
      // CREATE
      .addCase(sendCreateGroup.fulfilled, handleCreateFulfilled)

      // READ
      .addCase(fetchSingleGroup.pending, handleFetchSinglePending)
      .addCase(fetchSingleGroup.fulfilled, handleFetchSingleFulfilled)
      .addCase(fetchSingleGroup.rejected, handleFetchSingleRejected)
      .addCase(fetchGroupList.pending, handleFetchListPending)
      // because lists are returned from the server named for their resource, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchGroupList.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'groups'))
      .addCase(fetchGroupList.rejected, handleFetchListRejected)
      // permission protected single fetches
      .addCase(fetchSingleGroupAtEndpoint.pending, handleFetchSinglePending)
      // these endpoints return named lists, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchSingleGroupAtEndpoint.fulfilled, (state, action) => handleFetchSingleFromListFulfilled(state, action, 'groups'))
      .addCase(fetchSingleGroupAtEndpoint.rejected, handleFetchSingleRejected)
      // permission protected list fetches
      .addCase(fetchGroupListAtEndpoint.pending, handleFetchListPending)
      .addCase(fetchGroupListAtEndpoint.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'groups'))
      .addCase(fetchGroupListAtEndpoint.rejected, handleFetchListRejected)

      // UPDATE
      .addCase(sendUpdateGroup.pending, handleMutationPending)
      .addCase(sendUpdateGroup.fulfilled, handleMutationFulfilled)
      .addCase(sendUpdateGroup.rejected, handleMutationRejected)
      // .addCase(sendUpdateGroup.fulfilled, (state, action) => handleMutationFulfilled(state, action, (newState, action) => {
      //   // by passing this optional callback we now have access to the new state if we want to do something else with it, this works for all reducer handlers
      // }))

      // DELETE
      .addCase(sendDeleteGroup.pending, handleDeletePending)
      .addCase(sendDeleteGroup.fulfilled, handleDeleteFulfilled)
      .addCase(sendDeleteGroup.rejected, handleDeleteRejected)
      // JOIN GROUP
      .addCase(sendJoinPublicGroup.fulfilled, (state, action) => {
        // invalidate all group list queries, this way the existing lists of a user's groups will be updated
        Object.keys(state.listQueries).forEach(queryKey => {
          state.listQueries[queryKey].didInvalidate = true;
        });
      })
  }
});

// export the actions for the reducers defined above
export const { invalidateQuery, invalidateQueries, addGroupToList } = groupSlice.actions;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// updated to accept a listFetch function so we can use it for other list fetches (like public landing page groups)
export const fetchListIfNeeded = (queryKey, listFetch = fetchGroupList) => (dispatch, getState) => {
  const groupQuery = getState().group.listQueries[queryKey];
  if(shouldFetch(groupQuery)) {
    // console.log('Fetching group list', queryKey);
    dispatch(listFetch(queryKey));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
};

export const fetchSingleIfNeeded = (id, singleFetch = fetchSingleGroup) => (dispatch, getState) => {
  const groupQuery = getState().group.singleQueries[id];
  if(shouldFetch(groupQuery)) {
    dispatch(singleFetch(id));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
}

export default groupSlice.reducer;
