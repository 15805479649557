import { DateTime } from 'luxon';

/**
 * A map of the different types of date formats we use in the app.
 * @readonly
 * @enum {string}
 */
const DATE_FORMATS = {
  date: DateTime.DATE_SHORT
  , dateFull: DateTime.DATE_FULL
  , dateTime: DateTime.DATETIME_SHORT
  , dateTimeFull: DateTime.DATETIME_FULL
  , time: DateTime.TIME_SIMPLE
  , timeFull: DateTime.TIME_WITH_SHORT_OFFSET
}

/**
 * 
 * @param {string} date - date to be formatted 
 * @param {('date'|'dateFull'|'dateTime'|'dateTimeFull'|'time'|'timeFull')} dateFormat - format of the date
 * @returns {string} formatted date
 */
export const formatDate = (date, dateFormat = 'date') => {
  if(!date) return 'n/a';
  const format = DATE_FORMATS[dateFormat];
  return DateTime.fromISO(date).toLocaleString(format);
}

/**
 * 
 * @param {object} user - user object
 * @returns {string} user's permissions separated by a comma to be displayed in the UI
 */
export const getUserPermissionString = (user) => {
  if(!user) return 'n/a';
  const { admin, authoring, support, accountManager } = user;
  const strings = [];
  if(admin) strings.push('Admin');
  if(authoring) strings.push('Authoring');
  if(support) strings.push('Support');
  if(accountManager) strings.push('Account Manager');
  if(!strings.length) strings.push('User');
  return strings.join(', ');
}

export const getUserInitials = (user) => {
  if(!user) {
    console.error('ERROR: displayUtils.getUserInitials - user is undefined');
    return '';
  } else if(user.firstName && user.lastName) {
    return `${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`;
  } else if(user.username) {
    return user.username.charAt(0).toUpperCase();
  } else {
    return '?';
  }
}

export default {
  getUserInitials
}
