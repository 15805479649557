/**
 * Global AdminDefaultNav component. Up to the designers to decide if should live 
 * as a top-nav, fixed side, menu-prompted, etc. 
 * 
 * Recommended to select a good one from Tailwind UI that matches the brand 
 * and edit from there. 
 * https://tailwindui.com/components/application-ui/headings/page-headings
 */

// import primary libraries
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { UserCircleIcon } from '@heroicons/react/24/solid'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';


import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useLoggedInUser } from '../../../resources/user/authService';
import { getLinkToAdminTrainingManagement } from '../../utils/linkUtils';
import displayUtils from '../../utils/displayUtils'; 


// import { sendLogout } from '../../../resources/user/authStore';

// import NotificationDropdown from '../../../resources/notification/components/NotificationDropdown';


const DefaultNav = () => {

  // use the hook to get the loggedInUser from the authStore
  const loggedInUser = useLoggedInUser();

  // const dispatch = useDispatch();
  // const history = useHistory();
  const location = useLocation();

  // const handleLogout = async () => {
  //   dispatch(sendLogout()).then(response => {
  //     history.push("/");
  //   })
  // }

  return (
    <header className="border-b border-solid shadow-sm">
      <div className="h-14 pl-8 pr-0 md:px-8 flex flex-row justify-between bg-pf-slate-600 border-b border-b-pf-neutrone-500">
        <div className="flex my-auto">
          <img className="max-h-[2.5rem] w-full" src="/img/logo-white.png"/>
          { loggedInUser && loggedInUser.admin ? 
            <div className="my-auto">
              <NavLink to="/admin/groups" className="py-2 block whitespace-nowrap text-white text-sm ml-8">View Admin</NavLink>
            </div>
          : null
          }
        </div>
        <div className="w-1/5 flex justify-end ">
          {!loggedInUser ?
            <ul className="list-none p-0 flex flex-row">
              <li><NavLink to={{ pathname: "/user/login", state: { from: location } }} className="p-2 block text-white">Sign in</NavLink></li>
              <li><NavLink to={{ pathname: "/user/register", state: { from: location } }} className="p-2 block text-white">Register</NavLink></li>
            </ul>
            :
            <ul className="list-none p-0 flex flex-row items-center">
              {/* <li><NotificationDropdown classes="p-2 block" /></li> */}
              <li>
                {/* TODO: On small screens, display the hamburger icon that opens a menu with all of the navlinks */}
                <NavLink to="/user/profile" className="p-2 block text-white hover:no-underline">
                  <div className="flex h-10 w-10 rounded-full bg-pf-steel-200 border border-pf-white text-pf-black-800 font-semibold hover:bg-pf-steel-300">
                    <div className="m-auto">{displayUtils.getUserInitials(loggedInUser)}</div>
                  </div>
                </NavLink>
              </li>
            </ul>
          }
        </div>
      </div>
      <div className="h-[54px] bg-pf-slate-700">
        <div className="flex pl-6 h-full">
          <a href={`/groups`} className="my-auto py-4 px-2 md:py-2 md:px-4 rounded flex text-2xl md:text-base bg-pf-slate-600 text-pf-white hover:no-underline font-semibold tracking-[0.9px]">
            <i className="fa-solid fa-house fa-sm my-auto md:mr-2 text-pf-orange-600"></i>
            <div className="hidden md:block">DASHBOARD</div>
          </a>
          <div className="w-px bg-pf-stone-300 my-1 mx-5"/>
          <a href={`${window.trainingUrl}/my-agenda`} className="my-auto py-4 px-2 md:py-2 md:px-4 rounded flex text-2xl md:text-base text-pf-stone-300 hover:bg-pf-slate-600 hover:text-pf-white hover:no-underline font-semibold tracking-[0.9px] mr-5">
            <i className="fa-regular fa-graduation-cap fa-sm my-auto md:mr-2"></i>
            <div className="hidden md:block">TRAINING</div>
          </a>
          <a href={`${window.libraryUrl}`} className="my-auto py-4 px-2 md:py-2 md:px-4 rounded flex text-2xl md:text-base text-pf-stone-300 hover:bg-pf-slate-600 hover:text-pf-white hover:no-underline font-semibold tracking-[0.9px]">
            <i className="fa-light fa-books fa-sm my-auto md:mr-2"></i>
            <div className="hidden md:block">LIBRARY</div>
          </a>
        </div>
      </div>
    </header>
  )
}

export default DefaultNav;
