/**
 * Reads and exports the routes as defined by the admin folder for each resource.
 *
 * NOTE: this is not currently used by the CLI. It has to be manually updated (kind of on purpose, admin is god-mode after all)
 *
 * export { default as user } from './user/admin/AdminUserRouter.jsx'
 *
 * This will give it a route path called '/admin/users'
 */

import { lazy } from 'react';

// export { default as users } from '../resources/user/admin/AdminUserRouter.jsx';
// export { default as groups } from '../resources/group/admin/AdminGroupRouter.jsx';
// lazy load the routes so they aren't included in the intial bundle and don't load until they are needed or until we decide to preload them (currently not preloading admin routes)
export const users = lazy(() => import('../resources/user/admin/AdminUserRouter.jsx'));
export const groups = lazy(() => import('../resources/group/admin/AdminGroupRouter.jsx'));