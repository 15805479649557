/**
 * Will act as the default wrapper for all module states within the application
 * that call it within their own Layouts
 */

// import primary libraries
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types'
import Spinner from '../helpers/Spinner.jsx';
// TODO: Maybe find an alternative to react-helmet since it's out of date and causes react to throw warnings
// import { Helmet }  from 'react-helmet'

// import nav components
import AdminDefaultNav from '../navigation/AdminDefaultNav.jsx';
import DefaultNav from '../navigation/DefaultNav.jsx';
import { useLoggedInUser } from '../../../resources/user/authService';


const DefaultLayout = ({
  children
  , className = ''
  , title
}) => {

  const location = useLocation(); 
  // this can replace react-helmet if all we need to do is set the page title.
  useEffect(() => {
    document.title = title ? `${title} | Pro-ficiency` : "Pro-ficiency Hub";
  }, [title])

  return (
    <div className='h-full min-h-screen bg-pf-stone-200'>
      {/* <Helmet title={props.title || "Yote App"}/> */}
      {location.pathname.includes('admin') ?
      <AdminDefaultNav />
      :
      <DefaultNav />
      }
      <main className={`${className}`}>
        {children}
      </main>
    </div>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node)
    , PropTypes.node
  ]).isRequired
  , className: PropTypes.string
  , title: PropTypes.string
}


DefaultLayout.Skeleton = (props) => {
  return (
    <DefaultLayout {...props}>
      <Spinner />
    </DefaultLayout>
  )
}

export default DefaultLayout;
