import React, { useState } from 'react';

const CopyButton = ({ url }) => {
  const [buttonLabel, setButtonLabel] = useState('Copy Link');
  const [buttonColor, setButtonColor] = useState('bg-white');
  const [textColor, setTextColor] = useState('text-pf-slate-600')
  const [showCheck, setShowCheck] = useState(false); 

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setButtonLabel('Copied!');
      setButtonColor('bg-green-700');
      setTextColor('text-pf-white'); 
      setShowCheck(true); 
      setTimeout(() => {
        setButtonLabel('Copy Link');
        setButtonColor('bg-white');
        setTextColor('text-pf-slate-600');
        setShowCheck(false); 
      }, 1000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <button onClick={copyToClipboard} className={`px-5 py-1 border rounded-full ${buttonColor} ${textColor}`}>
      {buttonLabel}
      {showCheck ? 
      <i className="fa-solid fa-check ml-2"/>
      : null}
    </button>
  );
}

export default CopyButton; 