import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiUtils from '../../global/utils/api';

import {
  handleCreateFulfilled
  , handleFetchSinglePending
  , handleFetchSingleFulfilled
  , handleFetchSingleFromListFulfilled
  , handleFetchSingleRejected
  , handleFetchListPending
  , handleFetchListFulfilled
  , handleFetchListRejected
  , handleMutationPending
  , handleMutationFulfilled
  , handleMutationRejected
  , handleMutateManyPending
  , handleMutateManyFulfilled
  , handleMutateManyRejected
  , handleDeletePending
  , handleDeleteFulfilled
  , handleDeleteRejected
  , shouldFetch
  , INITIAL_STATE
  , handleInvalidateQuery
  , handleInvalidateQueries
  , handleAddSingleToList
} from '../../global/utils/storeUtils';

// import `Group` stuff that we need to use in the groupUser store
import { sendJoinPublicGroup } from '../group/groupStore';


// First define all API calls for groupUser
export const myGroupUsersEndpoint = 'logged-in'
export const activateByGroupEndpoint = (groupId) => {
  if(!groupId) return null;
  return `activate-by-group/${groupId}`
}
export const deactivateByGroupEndpoint = (groupId) => {
  if(!groupId) return null;
  return `deactivate-by-group/${groupId}`
}
export const deactivateByUserEndpoint = (userId) => {
  if(!userId) return null;
  return `deactivate-by-user/${userId}`
}
export const deactivateAllByGroupEndpoint = (groupId) => {
  if(!groupId) return null;
  return `deactivate-all-by-group/${groupId}`
}
export const downloadAllCSVByGroupEndpoint = (groupId) => {
  if(!groupId) return null;
  return `/api/group-users/export/${groupId}`
}
export const downloadCSVByGroupEndpoint = (groupId, groupUserIds) => {
  if(!groupId || !groupUserIds || !groupUserIds.length) return null;
  const ids = groupUserIds.join(',');
  return `/api/group-users/export/${groupId}?_id=${ids}`
}
/**
 * The functions below, called thunks, allow us to perform async logic. They
 * can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
 * will call the thunk with the `dispatch` function as the first argument. Async
 * code can then be executed and other actions can be dispatched. Thunks are
 * typically used to make async requests.
 * 
 * In practice we won't dispatch these directly, they will be dispatched by groupUserService which has a nicer api built on hooks.
 */


// fetch list by "study-completions" from redstone
export const groupUsersWithStudyCompletionEndpoint = (groupId) => {
  if(!groupId) return null;
  return `redstone/by-group-with-completion-statuses/${groupId}`
}

// CREATE
export const sendCreateGroupUser = createAsyncThunk(
  'groupUser/sendCreate'
  , async (newGroupUser) => {
    const groupId = newGroupUser?._group;
    if(!groupId) throw new Error('No groupId provided for new groupUser')
    const endpoint = `/api/group-users/${groupId}`;
    const response = await apiUtils.callAPI(endpoint, 'POST', newGroupUser);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// READ
export const fetchSingleGroupUser = createAsyncThunk(
  'groupUser/fetchSingle'
  , async (id) => {
    const endpoint = `/api/group-users/${id}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchGroupUserList = createAsyncThunk(
  'groupUser/fetchList' // this is the action name that will show up in the console logger.
  , async (listArgs) => {
    const endpoint = `/api/group-users${listArgs}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
// use the same endpoint as the list fetch and handle the array response to get the first item
export const fetchSingleGroupUserAtEndpoint = createAsyncThunk(
  'groupUser/fetchSingleWithFilter'
  , async (query) => {
    const endpoint = `/api/group-users${query}`; // example: `/api/groups/logged-in?${queryString}`
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const fetchGroupUserListAtEndpoint = createAsyncThunk(
  'groupUser/fetchListWithFilter' // this is the action name that will show up in the console logger.
  , async (query) => {
    const callAPI = query.includes('/redstone') ? apiUtils.callRedstoneAPI : apiUtils.callAPI;
    const endpoint = `/api/group-users${query}`; // example: `/api/groups/logged-in?${queryString}`
    const response = await callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const resendGroupInvite = createAsyncThunk(
  'groupUser/resendGroupInvite'
  , async ({ _group, _id }) => {
    if(!_group) throw new Error('No _group provided for resendGroupInvite')
    const endpoint = `/api/group-users/resend-invite/${_group}/${_id}`;
    const response = await apiUtils.callAPI(endpoint);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// UPDATE
export const sendUpdateGroupUser = createAsyncThunk(
  'groupUser/sendUpdate'
  , async ({ _id, ...updates }) => {
    const groupId = updates?._group;
    if(!groupId) throw new Error('No groupId provided for update groupUser')
    const endpoint = `/api/group-users/${groupId}/${_id}`;
    const response = await apiUtils.callAPI(endpoint, 'PUT', updates);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// UPDATE MANY
export const sendUpdateManyGroupUsers = createAsyncThunk(
  'groupUser/sendUpdateMany'
  // list can be an array of ids or an array of objects with an _id property, different endpoints will handle each case
  , async ({ endpoint, ids, groupId, userId, updates = {} }) => {
    let fullEndpoint = `/api/group-users/${endpoint}`;
    // let fullEndpoint = groupId ? `/api/group-users/${groupId}/${endpoint}` : `/api/group-users/${userId}/${endpoint}`;
    if(ids) fullEndpoint += `?_id=${ids.toString()}`
    const response = await apiUtils.callAPI(fullEndpoint, 'POST', updates);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const sendBulkUploadGroupUsers = createAsyncThunk(
  'groupUser/sendBulkCSVUpload'
  , async ({ groupId, file }) => {
    const endpoint = `/api/group-users/bulk-csv-upload/${groupId}`;
    const formData = new FormData();
    formData.append('file', file);
    // use `rawAPI` that doesn't stringify the body so we can send a file
    const response = await apiUtils.rawAPI(endpoint, 'POST', formData);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// DELETE
export const sendDeleteGroupUser = createAsyncThunk(
  'groupUser/sendDelete'
  , async (id) => {
    const endpoint = `/api/group-users/${id}`;
    const response = await apiUtils.callAPI(endpoint, 'DELETE');
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// next define the store's initial state, all of our store utils rely on a specific state shape, so use the constant
const initialState = { ...INITIAL_STATE };

// define the groupUserSlice. This is a combination of actions and reducers. More info: https://redux-toolkit.js.org/api/createSlice
export const groupUserSlice = createSlice({
  name: 'groupUser'
  , initialState
  /**
   * The `reducers` field lets us define reducers and generate associated actions.
   * Unlike the selectors defined at the bottom of this file, reducers only have access
   * to this specific reducer and not the entire store.
   * 
   * Again, we will not dispatch these directly, they will be dispatched by groupUserService.
   */
  , reducers: {
    invalidateQuery: handleInvalidateQuery
    , invalidateQueries: handleInvalidateQueries
    , addGroupUserToList: handleAddSingleToList
  }

  /**
   * The `extraReducers` field lets the slice handle actions defined elsewhere,
   * including actions generated by createAsyncThunk or in other slices.
   * We'll use them to track our server request status.
   * 
   * We'll add a case for each API call defined at the top of the file to dictate
   * what happens during each API call lifecycle.
   */
  , extraReducers: (builder) => {
    builder
      // CREATE
      .addCase(sendCreateGroupUser.fulfilled, handleCreateFulfilled)

      // READ
      .addCase(fetchSingleGroupUser.pending, handleFetchSinglePending)
      .addCase(fetchSingleGroupUser.fulfilled, handleFetchSingleFulfilled)
      .addCase(fetchSingleGroupUser.rejected, handleFetchSingleRejected)
      .addCase(fetchGroupUserList.pending, handleFetchListPending)
      // because lists are returned from the server named for their resource, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchGroupUserList.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'groupUsers'))
      .addCase(fetchGroupUserList.rejected, handleFetchListRejected)
      // permission protected single fetches
      .addCase(fetchSingleGroupUserAtEndpoint.pending, handleFetchSinglePending)
      // because lists are returned from the server named for their resource, we need to pass a `listKey` so the util can properly handle the response
      .addCase(fetchSingleGroupUserAtEndpoint.fulfilled, (state, action) => handleFetchSingleFromListFulfilled(state, action, 'groupUsers'))
      .addCase(fetchSingleGroupUserAtEndpoint.rejected, handleFetchSingleRejected)
      // permission protected list fetches
      .addCase(fetchGroupUserListAtEndpoint.pending, handleFetchListPending)
      .addCase(fetchGroupUserListAtEndpoint.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'groupUsers'))
      .addCase(fetchGroupUserListAtEndpoint.rejected, handleFetchListRejected)

      // UPDATE
      .addCase(sendUpdateGroupUser.pending, handleMutationPending)
      .addCase(sendUpdateGroupUser.fulfilled, handleMutationFulfilled)
      .addCase(sendUpdateGroupUser.rejected, handleMutationRejected)

      // BULK UPDATE/ACTION
      .addCase(sendUpdateManyGroupUsers.pending, handleMutateManyPending)
      .addCase(sendUpdateManyGroupUsers.fulfilled, (state, action) =>  handleMutateManyFulfilled(state, action, 'groupUsers'))
      .addCase(sendUpdateManyGroupUsers.rejected, handleMutateManyRejected)

      // BULK UPLOAD
      // we should be able to reuse the same fulfilled handler for bulk upload as we do for bulk update since they both return the same data (updated groupUsers)
      .addCase(sendBulkUploadGroupUsers.fulfilled, (state, action) =>  handleMutateManyFulfilled(state, action, 'groupUsers'))

      // .addCase(sendUpdateGroupUser.fulfilled, (state, action) => handleMutationFulfilled(state, action, (newState, action) => {
      //   // by passing this optional callback we now have access to the new state if we want to do something else with it, this works for all reducer handlers
      // }))

      // DELETE
      .addCase(sendDeleteGroupUser.pending, handleDeletePending)
      .addCase(sendDeleteGroupUser.fulfilled, handleDeleteFulfilled)
      .addCase(sendDeleteGroupUser.rejected, handleDeleteRejected)
    
      // JOIN GROUP
      // catch for the group action here so we can invalidate the groupUser lists that are used to fetch a user's groups
      .addCase(sendJoinPublicGroup.fulfilled, (state, action) => {
        Object.keys(state.listQueries).forEach(queryKey => {
          state.listQueries[queryKey].didInvalidate = true;
        });
      })
  }
});

// export the actions for the reducers defined above
export const { invalidateQuery, invalidateQueries, addGroupUserToList } = groupUserSlice.actions;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export const fetchListIfNeeded = (queryKey, listFetch = fetchGroupUserList) => (dispatch, getState) => {
  const groupUserQuery = getState().groupUser.listQueries[queryKey];
  if(shouldFetch(groupUserQuery)) {
    // console.log('Fetching groupUser list', queryKey);
    dispatch(listFetch(queryKey));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
};

export const fetchSingleIfNeeded = (id, singleFetch = fetchSingleGroupUser) => (dispatch, getState) => {
  const groupUserQuery = getState().groupUser.singleQueries[id];
  if(shouldFetch(groupUserQuery)) {
    dispatch(singleFetch(id));
  } else {
    // console.log('No need to fetch, fresh query in cache');
  }
}

export default groupUserSlice.reducer;
