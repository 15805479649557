/**
 * This is a utility to handle default API requests with the Yote server
 */

import _ from 'lodash'
// TODO: break this into separate exports so we aren't forced to import the entire set to use one method.

const apiUtils = {
  async callAPI(route, method = 'GET', body, headers = {
    'Accept': 'application/json', 'Content-Type': 'application/json',
  }) {
    const response = await fetch(route, {
      headers,
      method,
      credentials: 'same-origin',
      body: JSON.stringify(body)
    });
    // response.ok info: https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
    if(response.status && response.status === 401) {
      // console.log("REDIRECT ME")
      /**
       * NOTES FROM EQUIVALENT IN REDSTONE:
       * thoughts here. we want a way to loudly fail when the user is auto-logged out
       * vs silently breaking
       * i think a hard (non-redux) redirect is the way to go, since it should 
       * refresh the user from the server and wipe anything currently in the store
       */
      window.location.reload(); // preserve location on redirect

    } else if(response.ok) {
      return response.json();
    } else {
      const error = await response.json().catch(unhandledError => {
        // catch unhandled server errors
        console.error('Unhandled Error Thrown by Server. Not cool.', unhandledError)
        throw 'Something went wrong';
      });
      throw error;
    }
  }

  , async callRedstoneAPI(route, method = 'GET', body, headers = {
    'Accept': 'application/json', 'Content-Type': 'application/json',
  }) {
    route = route.replace('/redstone', '');
    const fullRoute = `${window.trainingUrl}${route}`;
    const response = await fetch(fullRoute, {
      headers,
      method,
      // this is required for redstone to recognize the request as coming from the same origin. Otherwise it will think we aren't logged in.
      // maybe not required for production, but definitely required for dev.
      credentials: 'include',
      body: JSON.stringify(body)
    });
    if(!response.ok) {
      // need to throw the error so redux thunk knows to trigger the 'rejected' action.
      // throw a generic message in this case (server failure) in other error cases, the server will return a message (handled below)
      throw `Error Fetching External Resource`
    }
    const { success, message, ...data } = await response.json().catch(unhandledError => {
      // catch unhandled server errors
      console.error('Unhandled Error Thrown by Server. Not cool.', unhandledError)
      throw 'Something went wrong';
    });
    if(success) {
      return data;
    } else {
      throw message;
    }
  }
  // do not json body before sending. used for file uploads, for ex.
  , async rawAPI(route, method = 'GET', body, headers = {
    // 'Accept': 'application/json', 'Content-Type': 'application/json'
  }) {
    const response = await fetch(route, {
      headers
      , method
      , credentials: 'same-origin'
      , body
    })
    // response.ok info: https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
    if(response.ok) {
      return response.json();
    } else {
      const error = await response.json().catch(unhandledError => {
        // catch unhandled server errors
        console.error('Unhandled Error Thrown by Server. Not cool.', unhandledError)
        throw 'Something went wrong';
      });
      throw error;
    }
  }
  , queryStringFromObject(queryObject) {
    // console.log("QUERY STRING FROM OBJECT")
    if(!queryObject) return "";
    if(queryObject === "all") return "";
    // ex: { page: '1', per: '20' } to ?page=1&per=20
    return Object.entries(queryObject)
      // remove empties
      .filter(entry => entry[1] && entry[1].toString().length > 0)
      // .filter(entry => entry[1] && entry[1].toString().length > 0)
      .map(item => {
        // debugging
        // console.log(item);
        return item;
      })
      // if value is array, convert to string, otherwise just add the string
      .map(entry => Array.isArray(entry[1]) ? [entry[0], entry[1].join(",")] : entry)
      // map to string
      .map(entry => entry.join("="))
      .join("&")
  }
  // example: ?page=1&per=20 to { page: '1', per: '20' }
  // example: ?foo=bar&redirect=http://app.com?query=string&other=stuff to { foo: 'bar', redirect: 'http://app.com?query=string&other=stuff' }
  // the previous version was a bit naive and didn't handle nested queries
  // this one is much more robust
  , objectFromQueryString(queryString) {
    if(queryString === "") return {};
    // Check if queryString is a non-empty string to avoid processing invalid inputs
    if(!queryString || typeof queryString !== 'string') {
      console.error("apiUtils.objectFromQueryString() requires a string argument, you provided: ", typeof queryString, queryString);
      return {};
    }
    // Remove the leading '?' if present
    const hasLeadingQuestionMark = queryString[0] === "?";
    queryString = hasLeadingQuestionMark ? queryString.substring(1) : queryString;

    const params = {};
    let key = '', value = '', inKey = true, inNestedQuery = 0;

    // Iterate through each character in the queryString
    for(let i = 0; i < queryString.length; i++) {
      const char = queryString[i];
      // Handle the transition from key to value on encountering '='
      if(char === '=' && inKey && inNestedQuery === 0) {
        inKey = false;
        continue;
      }
      // Handle the end of a key-value pair on encountering '&' outside of nested queries
      if(char === '&' && inNestedQuery === 0) {
        if(key) {
          params[_.camelCase(key)] = decodeURIComponent(value);
        }
        key = '';
        value = '';
        inKey = true;
        continue;
      }
      // Increase or decrease nested query depth on encountering '?' or '&' within nested queries
      if(char === '?') {
        inNestedQuery++;
      } else if(char === '&') {
        inNestedQuery--;
      }
      // Build the key or value based on the current state
      if(inKey) {
        key += char;
      } else {
        value += char;
      }
    }
    // Ensure the last key-value pair is added to params
    if(key) {
      params[_.camelCase(key)] = decodeURIComponent(value);
    }
    return params;
  }
  
  /**
   * Checks if listArgs are ready to be fetched
   * returns false if listArgs is falsy, or an object with any undefined or empty array values
   * returns true if listArgs is an object with no undefined or empty array values (this includes an empty object which is a valid listArgs)
   * @param {object} listArgs - an object containing the listArgs to be checked
   */
  , checkListArgsReady(listArgs) {
    // can't fetch if no list args are provided
    if(!listArgs) return false;
    if(typeof listArgs !== "object") return false;
    let listArgsReady = true;
    // if ANY list args are undefined or an array with 0 length, flip the boolean false
    Object.keys(listArgs).forEach(key => {
      if((listArgs[key] === undefined) || (Array.isArray(listArgs[key]) && listArgs[key].length === 0)) {
        listArgsReady = false;
      }
    });
    return listArgsReady;
  }
}

export default apiUtils;


