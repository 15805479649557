/**
 * Helper component for rendering basic slider inputs (styled checkbox inputs)
 */

// import primary libraries
import React from 'react';
import PropTypes from 'prop-types';

const SliderInput = ({
  change
  , disabled
  // , helpText // TODO: add help text maybe?
  , label
  , name
  , value = false
}) => {
  return (
    <div className="flex flex-row">
      <label className="relative group flex py-2 text-sm">
        <input className="sr-only peer"
          checked={!!value}
          disabled={disabled}
          type="checkbox" 
          onChange={e => {
            change({
              target: {
                name: name
                , value: e.target.checked
              }
            })
          }} 
        />
        <span className={`${!disabled && 'group-hover:after:translate-x-1'} w-12 h-6 flex p-1 bg-gray-300 rounded-full duration-300 ease-in-out ${disabled ? 'peer-checked:bg-pf-orange-300 cursor-not-allowed' : 'peer-checked:bg-pf-orange-600'} after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-6`}/>
      </label>
      { label ? 
        <div className="flex flex-col justify-center p-2 text-sm whitespace-nowrap">
          <p className={disabled ? "text-pf-neutrone-300" : "text-pf-slate-600"}>{label}</p>
        </div>
      : null
      }
    </div>
  )
}

SliderInput.propTypes = {
  change: PropTypes.func.isRequired
  , disabled: PropTypes.bool
  // , helpText: PropTypes.any
  , label: PropTypes.string
  , name: PropTypes.string
  , value: PropTypes.bool
}

SliderInput.defaultProps = {
  disabled: false
  // , helpText: null
  , label: ''
}

export default SliderInput;
