/**
 * View component for /user/login
 *
 * On successful login this component forwards the user back to referrer
 * or to the root if there is no referrer.
 *
 * NOTE: upon reaching this page, user can toggle between /user/login and
 * /user/register without changing the original referring source route.
 */
// import primary libraries
import React, { lazy, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// actions
import { sendLogin, sendGetLoginOptions } from '../authStore';
import { useLoggedInUser } from '../authService';

// utils
import apiUtils from '../../../global/utils/api';
import { safeRedirect } from '../../../global/utils/linkUtils.js';


// import user components
import UserLayout from '../components/UserLayout.jsx';
import { EmailInput, PasswordInput } from '../../../global/components/forms';

const Modal = lazy(() => import('../../../global/components/base/Modal'));

const UserLogin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // check for email in params to pre-fill the username field on the login form
  const { email, redirect, error: rawSSOError } = apiUtils.objectFromQueryString(location.search);

  const [userEmail, setUserEmail] = useState(email || '');
  const [inputEmail, setInputEmail] = useState('');
  const [loginOptions, setLoginOptions] = useState(null);

  const submitUserEmail = (e) => {
    e?.preventDefault && e.preventDefault();
    setUserEmail(inputEmail);
  }

  const loggedInUser = useLoggedInUser();
  const [user, setUser] = useState({ username: email || '', password: '' });
  const [error, setError] = useState('');
  const [SSOError, setSSOError] = useState(null);

  const getLoginOptions = async () => {
    if (userEmail) {
      const { payload: loginOptions, error } = await dispatch(sendGetLoginOptions(userEmail));
      if (error) setError(error?.message || "There was a problem logging in. Please try again");
      setLoginOptions(loginOptions || {});
    }
  }

  useEffect(() => {
    if (userEmail) {
      setUser({ ...user, username: userEmail });
      getLoginOptions();
    }
  }, [userEmail]);

  // keep the SSO error in sync with the raw error from the query string but this way we can allow user to clear it as well
  useEffect(() => {
    if (rawSSOError !== SSOError) {
      setSSOError(rawSSOError);
    }
  }, [rawSSOError]);


  useEffect(() => {
    // this will run on mount and redirect if user is already logged in
    // it will also run on every update to loggedInUser (when the user logs in)
    if (loggedInUser) {
      // const searchObject = apiUtils.objectFromQueryString(location.search);
      // can't use react-router's history object here because we may be directing to an external site.
      // history.push(searchObject.redirect || "/")
      safeRedirect(redirect || "/")
    }
  }, [loggedInUser, redirect]);

  const handleSubmit = async (e) => {
    setError('');
    e.preventDefault();
    const { error } = await dispatch(sendLogin(user));
    // generic error message for security reasons
    if (error) {
      setError(error?.message || "There was a problem logging in. Please try again");
      // reset password field on error
      setUser({ ...user, password: '' });
    }
  }

  const resetForm = () => {
    setUser({ username: '', password: '' });
    setError('');
    setUserEmail('');
    setInputEmail('');
    setLoginOptions(null);
  }

  return (
    <UserLayout
      className='bg-white grid grid-cols-12 lg:gap-8 gap-4 w-full 2xl:w-11/12 min-h-screen pt-8 xl:px-16 md:px-8 px-2 mx-auto'
      title='Sign in'
    >
      {!userEmail ? (
        <div className='lg:col-span-5 lg:mx-0 col-span-12 max-w-3xl mx-auto'>
          <img className="p-2 max-w-full h-12" src="/img/logo.png" alt='logo' />
          <h1 className='text-pf-slate-600 text-[56px]'> Welcome! </h1>
          <form name='userLoginForm' onSubmit={submitUserEmail}>
            <EmailInput
              label='Email'
              name='username'
              value={inputEmail}
              change={e => setInputEmail(e.target.value)}
              autoFocus={true}
            />
            <button
              className='btn block w-full'
              disabled={!inputEmail}
            >
              Continue &rarr;
            </button>
          </form>
          {error && (
            <div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 my-4 rounded relative' role='alert'>
              <strong className='font-bold'>Error! </strong>
              <span className='block sm:inline'>{error}</span>
            </div>
          )}
          {SSOError && (
            <div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 my-4 rounded relative' role='alert'>
              <span className='block sm:inline'>{getFriendlyError(SSOError)}</span>
              <button
                className='absolute top-0 bottom-0 right-0 px-4 py-3'
                type='button'
                onClick={(e) => {
                  e.preventDefault();
                  setSSOError(null);
                }}>
                <i className='fas fa-times'></i>
              </button>
            </div>
          )}
        </div>
      )
      : !loginOptions ? (
        <div className='lg:col-span-5 lg:mx-0 col-span-12 max-w-3xl mx-auto'>
          <LoginOptionsHeader userEmail={userEmail} resetForm={resetForm} redirect={redirect}>
            <p className='my-4'>Please wait while we look up your credentials...</p>
          </LoginOptionsHeader>
        </div>
      )
      : loginOptions?.accountLocked ? (
        <div className='lg:col-span-5 lg:mx-0 col-span-12 max-w-3xl mx-auto'>
          <LoginOptionsHeader userEmail={userEmail} resetForm={resetForm} redirect={redirect}>
            <div className='group bg-pf-stone-100 p-6 border border-transparent hover:border-pf-stone-300 rounded-lg hover:shadow flex flex-col gap-4 my-4 transition-all'>
              <p className='mb-4'>Your account is locked</p>
              <Link to={`/user/forgot-password${location.search ? location.search + `&email=${userEmail}` : `?email=${userEmail}`}`} className='btn-login-muted w-full block group-hover:btn group-hover:w-full group-hover:block transition-all hover:no-underline'>Reset Password</Link>
            </div>
          </LoginOptionsHeader>
        </div>
      )
      :
      (
        <div className='lg:col-span-5 lg:mx-0 col-span-12 max-w-3xl mx-auto'>
          <LoginOptionsHeader userEmail={userEmail} resetForm={resetForm} redirect={redirect}>
            <p className='my-4'>Choose from the options below</p>
          </LoginOptionsHeader>
          {error && (
            <div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 my-4 rounded relative' role='alert'>
              <strong className='font-bold'>Error! </strong>
              <span className='block sm:inline'>{error}</span>
            </div>
          )}
          {loginOptions?.SSOLinks?.length > 0 && (
            <div className='group bg-pf-stone-100 p-6 border border-transparent hover:border-pf-stone-300 rounded-lg hover:shadow flex flex-col gap-4 mb-4 transition-all'>
              <p className='text-pf-neutrone-500 group-hover:text-pf-black-900 transition-all'>Sign in via</p>
              {loginOptions.SSOLinks.map((link, i) => (
                <div className='btn-login-muted w-full block group-hover:btn group-hover:w-full group-hover:block transition-all' key={link.name + '_' + i}>
                  <a
                    className='text-inherit hover:no-underline h-full w-full block'
                    href={redirect ? link.link + `?RelayState=${encodeURIComponent(`${redirect}`)}` : link.link}
                  >
                    {link.name}
                  </a>
                </div>
              )
              )}
            </div>
          )}
          {loginOptions?.hasPassword ? (
            <div className='group bg-pf-stone-100 p-6 border border-transparent hover:border-pf-stone-300 rounded-lg hover:shadow flex flex-col gap-4 mb-4 transition-all'>
              <p className='text-pf-neutrone-500 group-hover:text-pf-black-900 transition-all'>Enter Password</p>
              <form name='userLoginForm' onSubmit={handleSubmit}>
                <PasswordInput
                  name='password'
                  value={user.password}
                  change={e => setUser({ ...user, [e.target.name]: e.target.value })}
                  required={true}
                  className='bg-inherit focus:bg-white group-hover:bg-white  transition-all'
                  autoFocus={true}
                />
                <button className='btn-login-muted w-full block group-hover:btn group-hover:w-full group-hover:block group-hover:disabled:cursor-default transition-all' type='submit' disabled={!user.password} >Sign in</button>
              </form>
              <Link to={`/user/forgot-password${!location.search ? `?email=${userEmail}` : !!email ? location.search : `${location.search}&email=${userEmail}`}`} className='text-pf-neutrone-500 group-hover:text-info-700 transition-all w-fit'>Password trouble?</Link>
            </div>
          )
          : loginOptions?.hasPassword === false ? (
            <div className='group bg-pf-stone-100 p-6 border border-transparent hover:border-pf-stone-300 rounded-lg hover:shadow flex flex-col gap-4 mb-4 transition-all'>
              <p className='text-pf-neutrone-500 group-hover:text-pf-black-900 transition-all'>Looks like you don't have a password</p>
              <Link to={`/user/forgot-password${!location.search ? `?email=${userEmail}` : !!email ? location.search : `${location.search}&email=${userEmail}`}`} className='btn-login-muted w-full block group-hover:btn group-hover:w-full group-hover:block transition-all hover:no-underline'>Set up password</Link>
            </div>
          )
          : null}
          {loginOptions?.allowMagicLink && (
            <div className='group bg-pf-stone-100 p-6 border border-transparent hover:border-pf-stone-300 rounded-lg hover:shadow flex flex-col gap-4 mb-4 transition-all'>
              <p className='text-pf-neutrone-500 group-hover:text-pf-black-900 transition-all'>Sign in with a one-time link</p>
              <button
                className='btn-login-muted w-full block group-hover:btn group-hover:w-full group-hover:block transition-all'
                onClick={() => alert('TODO: This feature is not yet implemented')}
              >
                Send link to email
              </button>
            </div>
          )}
        </div>
      )}
      <img className='lg:col-span-7 col-span-12 max-w-full mx-auto rounded-3xl hidden lg:flex' loading='lazy' src='/img/pro-hero.png' alt='hero' />
    </UserLayout>
  )
}

export default UserLogin;

const LoginOptionsHeader = ({ userEmail, resetForm, redirect, children }) => {
  const resetLink = `/user/login${redirect ? `?redirect=${redirect}` : ''}`;

  return (
    <>
      <img className="p-2 max-w-full h-12" src="/img/logo.png" alt='logo' />
      <h1 className='text-pf-slate-600 text-[56px]'> Welcome! </h1>
      {/* if they edit the email we'll want to clear any email in params, so link with redirect but without email */}
      <Link className='text-2xl text-pf-black-900 mt-8 font-semibold hover:cursor-pointer hover:no-underline group' to={resetLink} onClick={resetForm}>
        <span>{userEmail}</span>
        <span className='text-transparent text-sm group-hover:text-danger-700 transition-all ml-2'>clear email</span>
      </Link>
      {children}
    </>
  )
}


const getFriendlyError = (error) => {
  switch (error) {
    // ref returns on SessionController samlLogin and samlLoginCallback
    case 'provider_error_1':
      return 'Failed to Initiate SAML Login with Provider';
    case 'provider_error_2':
      return 'Error Authenticating with SAML Login Provider';
    case 'provider_error_3':
      return 'Failed to Authenticate with SAML Login Provider';
    case 'no_matching_user_1':
      return 'Unable to Locate Matching User. Please try again.';
    case 'no_matching_user_2':
      return 'Unable to Locate Matching User. Please try again.';
    case 'not_allowed_1':
      return 'Permission Denied. Please try again.';
    case 'not_allowed_2':
      return 'Unable To Log In As Global Admin Via SSO. Please Contact Admin.';
    default:
      return error;
  }
}
