/**
 * Reads and exports the routes as defined by each resource module.
 *
 * NOTE: this facilitates adding routes via the CLI. The CLI will automatically
 * build these exports with the camelCase version of the resource name so as to
 * add a consistent top-level path to the resource. A resource named UserWorkout
 * will show below as follows:
 *
 * export { default as userWorkout } from './userWorkout/UserWorkoutRouter.jsx'
 *
 * This will give it a top-level route path called 'user-workouts'
 */

import { lazy } from 'react';

// export { default as user } from '../resources/user/UserRouter.jsx';
// export { default as products } from '../resources/product/ProductRouter.jsx';
// export { default as groups } from '../resources/group/GroupRouter.jsx';
// export { default as groupUsers } from '../resources/groupUser/GroupUserRouter.jsx'; // TODO: add user facing groupUser routes
// export { default as groupRoles } from '../resources/groupRole/GroupRoleRouter.jsx';
// export { default as organizations } from '../resources/organization/OrganizationRouter.jsx';
// export { default as courses } from '../resources/course/CourseRouter.jsx';
// export { default as userModules } from '../resources/userModule/UserModuleRouter.jsx';
// export { default as certificates } from '../resources/certificate/CertificateRouter.jsx';
// export { default as contentModules } from '../resources/contentModule/ContentModuleRouter.jsx';
// export { default as auditLogs } from '../resources/auditLog/AuditLogRouter.jsx';
// lazy load the routes so they aren't included in the intial bundle and don't load until they are needed or until we decide to preload them (on App.jsx)
export const user = lazy(() => import('../resources/user/UserRouter.jsx'));
export const products = lazy(() => import('../resources/product/ProductRouter.jsx'));
export const groups = lazy(() => import('../resources/group/GroupRouter.jsx'));
export const userProfiles = lazy(() => import('../resources/userProfile/UserProfileRouter.jsx'));
export const organizations = lazy(() => import('../resources/organization/OrganizationRouter.jsx'));
export const courses = lazy(() => import('../resources/course/CourseRouter.jsx'));
export const userModules = lazy(() => import('../resources/userModule/UserModuleRouter.jsx'));
export const certificates = lazy(() => import('../resources/certificate/CertificateRouter.jsx'));
export const contentModules = lazy(() => import('../resources/contentModule/ContentModuleRouter.jsx'));
export const auditLogs = lazy(() => import('../resources/auditLog/AuditLogRouter.jsx'));