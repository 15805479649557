/**
 * Global AdminDefaultNav component. Up to the designers to decide if should live 
 * as a top-nav, fixed side, menu-prompted, etc. 
 * 
 * Recommended to select a good one from Tailwind UI that matches the brand 
 * and edit from there. 
 * https://tailwindui.com/components/application-ui/headings/page-headings
 */

// import primary libraries
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { UserCircleIcon } from '@heroicons/react/24/solid'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';


import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useLoggedInUser } from '../../../resources/user/authService';
import { getLinkToAdminTrainingManagement } from '../../utils/linkUtils';
import displayUtils from '../../utils/displayUtils';


// import { sendLogout } from '../../../resources/user/authStore';

// import NotificationDropdown from '../../../resources/notification/components/NotificationDropdown';


const AdminDefaultNav = () => {

  // use the hook to get the loggedInUser from the authStore
  const loggedInUser = useLoggedInUser();

  // const dispatch = useDispatch();
  // const history = useHistory();
  const location = useLocation();

  // const handleLogout = async () => {
  //   dispatch(sendLogout()).then(response => {
  //     history.push("/");
  //   })
  // }

  return (
    <header className=" shadow-sm">
      <div className="h-14 w-full px-8 flex flex-row bg-pf-slate-600">
        <div className="w-fit flex my-auto mr-16">
          <img className="max-h-[2.5rem] w-full" src="/img/logo-white.png" />
          <p className="my-auto ml-2 text-gray-400">Admin</p>
        </div>
        {loggedInUser && loggedInUser.admin && (
          <div className="flex-row w-full overflow-auto hidden sm:flex my-auto">
            <div className={location.pathname.includes('/admin/dashboard') ? 'bg-pf-slate-500' : null}>
              <NavLink to="/groups" className="py-2 px-4 block whitespace-nowrap text-white text-sm">View Dashboard</NavLink>
            </div>
            <div className={location.pathname.includes('/admin') ? 'bg-pf-slate-500 rounded' : null}>
              <NavLink to="/admin/groups" className="py-2 px-4 block whitespace-nowrap text-white text-sm">Access Management</NavLink>
            </div>
            <div className={location.pathname.includes('/admin/contentModule') ? 'bg-pf-slate-500 rounded' : null}>
              <a href={getLinkToAdminTrainingManagement()} className="py-2 px-4 block whitespace-nowrap text-white text-sm">Training Management
                <ArrowTopRightOnSquareIcon className={`h-5 inline ml-2 mb-1`} />
              </a>
            </div>
          </div>
        )}
        <div className="w-1/4 flex justify-end mr-3">
          {!loggedInUser ?
            <ul className="list-none p-0 flex flex-row">
              <li><NavLink to={{ pathname: "/user/login", state: { from: location } }} className="p-2 block text-white">Sign in</NavLink></li>
              <li><NavLink to={{ pathname: "/user/register", state: { from: location } }} className="p-2 block text-white">Register</NavLink></li>
            </ul>
            :
            <ul className="list-none p-0 flex flex-row items-center">
              {/* <li><NotificationDropdown classes="p-2 block" /></li> */}
              <li>
                {/* TODO: On small screens, display the hamburger icon that opens a menu with all of the navlinks */}
                <NavLink to="/user/profile" className="block text-white justify-end">
                  <div className="flex h-10 w-10 rounded-full bg-pf-steel-200 border border-pf-white text-pf-black-800 font-semibold hover:bg-pf-steel-300">
                    <div className="m-auto">{displayUtils.getUserInitials(loggedInUser)}</div>
                  </div>
                </NavLink>
              </li>
            </ul>
          }
        </div>
      </div>
      {location.pathname.includes('/admin') ?
        <div className="py-2 px-6 flex flex-row border-y border-y-pf-neutrone-500 bg-pf-slate-600">
          <div className="flex flex-col justify-center">
            <ul className="list-none p-0 flex flex-row">
              {loggedInUser && loggedInUser.admin && (
                <>
                  <li className={location.pathname.includes('/admin/groups') ? 'bg-pf-slate-500 rounded mr-8' : 'mr-8'}>
                    <NavLink to="/admin/groups" className="py-2 px-2 block text-white text-sm">Groups</NavLink>
                  </li>
                  <li className={location.pathname.includes('/admin/users') ? 'bg-pf-slate-500 rounded' : null}>
                    <NavLink to="/admin/users" className="py-2 px-2 block text-white text-sm">Users</NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
        : null
      }
    </header>
  )
}

export default AdminDefaultNav;
