/**
 * A set of functions to get links to all of the external pages that are used in the app.
 * It may seem like overkill to have a function for each link, but it makes it easier to
 * change the links later (which is definitely going to happen) without having to search
 * through the codebase for all the links.
 */

// get external links to the various pages (names are likely to change)

// let protocol be set directly for each env
const trainingBaseUrl = window.trainingUrl; // FULL url including protocol
const libraryBaseUrl = window.libraryUrl; // FULL url including protocol



// const protocol = window.location.protocol; // should be `http` on dev, `https` on staging/prod

/**
 * @returns {string} the endpoint for the the admin training management view
 */
export const getLinkToAdminTrainingManagement = () => {
  return `${trainingBaseUrl}/admin/content-management/studies`;
}

/**
 * 
 * @param {object} group - the group object
 * @returns {string} the link to the user's training page for the group
 */
export const getLinkToTraining = (group) => {
  if(!group || !group.slug) {
    // console.error('getLinkToTraining: no group slug provided');
    return null;
  }
  return `${trainingBaseUrl}/my-agenda/program/${group.slug}`;
}

export const getLinkToLibrary = (group) => {
  if(!group || !group._id) {
    // console.error('getLinkToTraining: no group slug provided');
    return null;
  }
  return `${libraryBaseUrl}/${group._id}`;
}

/**
 * 
 * @param {object} group - the group object
 * @returns {string} the link to the group history page
 */
export const getLinkToGroupHistory = (group) => {
  if(!group || !group._id) {
    // console.error('getLinkToGroupHistory: no group id provided');
    return null;
  }
  return `${trainingBaseUrl}/admin/audit-logs/study/${group._id}`;
}

/**
 * 
 * @param {object} groupId - the group id
 * @returns {string} the link to the group history page
 */
export const getLinkToGroupDashboard = (groupId) => {
  if(!groupId) {
    // console.error('getLinkToGroupDashboard: no group id provided');
    return null;
  }
  return `${trainingBaseUrl}/studies-dashboard/${groupId}`;
}

/**
 * 
 * @param {object} groupId - the group id
 * @returns {string} the link to the group history page
 */
// TODO: the naming/usage of these needs some attention
export const getLinkToGroupTraining = (groupId) => {
  if(!groupId) {
    // console.error('getLinkToGroupTraining: no group id provided');
    return null;
  }
  return `${trainingBaseUrl}/admin/content-management/studies/${groupId}`;
}

/**
 * 
 * @param {object} groupId - the group id
 * @returns {string} the link to the group history page
 */
export const getLinkToGroupSites = (groupId) => {
  if(!groupId) {
    // console.error('getLinkToGroupSites: no group id provided');
    return null;
  }
  return `${trainingBaseUrl}/admin/content-management/studies/${groupId}/sites`;
}

/**
 * 
 * @param {object} groupId - the group id
 * @returns {string} the link to the group history page
 */
export const getLinkToGroupRoles = (groupId) => {
  if(!groupId) {
    // console.error('getLinkToGroupRoles: no group id provided');
    return null;
  }
  return `${trainingBaseUrl}/admin/content-management/studies/${groupId}/roles`;
}

/**
 * 
 * @param {object} groupUser - the groupUser object
 * @returns {string} the link to the groupUser history page
 */
export const getLinkToGroupUserHistory = (groupUser) => {
  if(!groupUser || !groupUser._id) {
    // console.error('getLinkToGroupHistory: no group id provided');
    return null;
  }
  return `${trainingBaseUrl}/admin/audit-logs/study-user/${groupUser._id}`;
}

/**
 * @returns {string} the endpoint for the the admin user role report (downloadable csv)
 */
export const getLinkToAdminRoleReport = () => {
  return `${trainingBaseUrl}/api/users/role-report`;
}

/**
 * @param userId {string} the id of the user
 * @returns {string} the link to the admin user audit log page
 */
export const getLinkToAdminUserAuditLogs = (userId) => {
  return `${trainingBaseUrl}/admin/audit-logs/user/${userId}`;
}

/**
 * @param {string} userId - the id of the user
 * @returns {string} the endpoint for the user account activity report (downloadable csv)
 */
export const getLinkToAdminUserActivityReport = (userId) => {
  return `${trainingBaseUrl}/api/user-events/report/user-account-activity/${userId}`;
}

export const safeRedirect = (redirectUrl, replace = false) => {
  const redirectStrategy = replace ? 'replace' : 'assign';
  if(isSafeUrl(redirectUrl)) {
    console.log("redirect url is safe", redirectUrl)
    window.location[redirectStrategy](redirectUrl);
  } else {
    console.error("redirectUrl is not safe", redirectUrl);
    // essentially a redirect to the homepage with no params
    window.location[redirectStrategy](window.location.origin);
  }
}

// a safe url is one that is on the same domain as the app (or localhost if in dev)
const isSafeUrl = (url) => {
  const safeOrigin = window.location.origin; // "http://localhost:3000" or "https://hub.p-f.com" or "https://hub.pro-ficiency.com"
  // grab our current domain (e.g. "p-f" or "pro-ficiency" or "localhost")
  const safeDomain = safeOrigin.split('.').slice(1)[0] || 'localhost';
  // create a valid URL object from the passed in url string, pass in our origin as a base to account for relative urls (if url is absolute, this will be ignored)
  // NOTE: this helps prevent XSS attacks because the url will be stringified and re-parsed, which will remove any malicious code
  const redirectUrl = new URL(url, safeOrigin);
  const isLocal = redirectUrl.origin.includes('localhost') && safeDomain.includes('localhost');
  // now that we have a valid URL object, we can get the domain to make sure it's safe
  const redirectDomain = redirectUrl.hostname.split('.').slice(1)[0]; // "p-f" or "pro-ficiency" or something not allowed
  const isSameDomain = safeDomain === redirectDomain;
  // allow redirect to any of our subdomains (e.g. hub.p-f.com, training.p-f.com, etc. or localhost)
  const isSafe = isLocal || isSameDomain;
  return isSafe;
}
