import React from 'react';
import { Redirect } from 'react-router-dom';
import { useLoggedInUser } from '../../../resources/user/authService';
import DefaultLayout from '../layouts/DefaultLayout';

const Landing = () => {
  const loggedInUser = useLoggedInUser();
  if(!loggedInUser) {
    return <Redirect to='/user/login' />
  } else if(!loggedInUser.admin) {
    return <Redirect to={'/groups'} />
  } else if(loggedInUser.admin) {
    // little redundant but just in case we get some weird infinite looping
    return <Redirect to={'/admin/groups'} />
  }

  return null;
}

export default Landing;